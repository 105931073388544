import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select"; // Librería para selects con búsqueda
import { FaPlus, FaTrash } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  LISTAR_MOTIVOS,
  LISTAR_TIPO_PROTESTA,
  LISTAR_TRAMITES,
  CREAR_PROTESTA,
} from "../../gql/protestaCiudadana";
import { LISTAR_DEPENDENCIAS } from "../../gql/protestaCiudadana";
import MessageModal from "../Tools/MessageModal";

const ProtestForm = () => {
  const [fileInputs, setFileInputs] = useState([{ id: 1 }]);
  const [tramitesOptions, setTramitesOptions] = useState([]);
  const [dependenciasOptions, setDependenciasOptions] = useState([]);
  const [tipoProtestaOptions, setTipoProtestaOptions] = useState([]);
  const [motivosOptions, setMotivosOptions] = useState([]);
  const [crearProtesta] = useMutation(CREAR_PROTESTA);

  /** Este apartado es para el Modal */
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");

  // Query para listar dependencias
  const { data: dependenciasData, loading: dependenciasLoading } =
    useQuery(LISTAR_DEPENDENCIAS);

  const { data: tipoProtestaData, loading: tipoProtestaLoading } =
    useQuery(LISTAR_TIPO_PROTESTA);

  const { data: motivosData, loading: motivosLoading } =
    useQuery(LISTAR_MOTIVOS);

  // Query para listar trámites
  // Lazy query para listar trámites
  const [fetchTramites, { data: tramitesData, loading: tramitesLoading }] =
    useLazyQuery(LISTAR_TRAMITES);

  // Manejar las dependencias obtenidas
  useEffect(() => {
    if (dependenciasData) {
      setDependenciasOptions(
        dependenciasData.obtenerDependenciaQuery.map((dep) => ({
          value: dep.idDep,
          label: dep.nombre,
        }))
      );
    }
  }, [dependenciasData]);

  // Manejar los tipos de protesta obtenidos
  useEffect(() => {
    if (tipoProtestaData) {
      setTipoProtestaOptions(
        tipoProtestaData.obtenerTipoProtestaQuery.map((tipo) => ({
          value: tipo.id,
          label: tipo.tipo,
        }))
      );
    }
  }, [tipoProtestaData]);

  // Manejar los motivos obtenidos
  useEffect(() => {
    if (motivosData) {
      setMotivosOptions(
        motivosData.obtenerMotivosProtestaQuery.map((mot) => ({
          value: mot.idMotivo,
          label: mot.motivo,
        }))
      );
    }
  }, [motivosData]);

  // Manejar los trámites obtenidos
  // Manejar los trámites obtenidos
  useEffect(() => {
    if (tramitesData) {
      console.log("Trámites obtenidos:", tramitesData);
      setTramitesOptions(
        tramitesData.obtenerTramitesQuery.map((tra) => ({
          value: tra.idTramite,
          label: tra.nombre,
        }))
      );
    }
  }, [tramitesData]);

  const handleAddInput = () => {
    if (fileInputs.length < 3) {
      setFileInputs([...fileInputs, { id: fileInputs.length + 1 }]);
    }
  };

  const handleRemoveInput = (id) => {
    setFileInputs(fileInputs.filter((input) => input.id !== id));
  };

  const handleDependenciaChange = (selectedOption) => {
    // Actualizar el valor de la dependencia en Formik
    formik.setFieldValue("dependencia", selectedOption?.value || "");

    // Limpiar el trámite seleccionado
    formik.setFieldValue("tramite", ""); // Limpia el valor en Formik
    setTramitesOptions([]); // Limpia visualmente los trámites

    // Obtener trámites de la nueva dependencia seleccionada
    if (selectedOption?.value) {
      fetchTramites({ variables: { idDep: selectedOption.value } });
    }
  };

  const handleMotivoChange = (selectedOption) => {
    // actualizar el valor del motivo en Formik
    formik.setFieldValue("motivo", selectedOption?.value || "");
  };

  const handleTipoProtestaChange = (selectedOption) => {
    // actualizar el valor del tipo de protesta en Formik
    formik.setFieldValue("tipoProtesta", selectedOption?.value || "");
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      correo: "",
      tramite: "",
      dependencia: "",
      folio: "",
      ligaTramite: "",
      motivo: "",
      tipoProtesta: "",
      descripcionHechos: "",
      fechaHechos: "",
      lugarHechos: "",
      servidorPublico: "",
      archivos: [],
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("El nombre es obligatorio"),
      primerApellido: Yup.string().required(
        "El primer apellido es obligatorio"
      ),
      correo: Yup.string()
        .email("Debe ser un correo válido")
        .required("El correo electrónico es obligatorio"),
      tramite: Yup.string().required("Debe seleccionar un trámite"),
      tipoProtesta: Yup.string().required(
        "Debe seleccionar el tipo de protesta"
      ),
      motivo: Yup.string().required("El motivo de la protesta es obligatorio"),
      descripcionHechos: Yup.string().required(
        "La descripción de los hechos es obligatoria"
      ),
      fechaHechos: Yup.date().required(
        "La fecha y hora de los hechos es obligatoria"
      ),
      lugarHechos: Yup.string().required(
        "El lugar de los hechos es obligatorio"
      ),
    }),
    onSubmit: async (values) => {
      try {
        console.log("Formulario enviado:", values);
        let data = await crearProtesta({
          variables: {
            input: {
              nombre: values.nombre,
              primerApellido: values.primerApellido,
              segundoApellido: values.segundoApellido,
              correo: values.correo,
              tramite: values.tramite,
              dependencia: values.dependencia,
              folio: values.folio,
              ligaTramite: values.ligaTramite,
              tipoProtesta: values.tipoProtesta,
              descripcionHechos: values.descripcionHechos,
              fechaHechos: values.fechaHechos,
              lugarHechos: values.lugarHechos,
              servidorPublico: values.servidorPublico,
              motivo: values.motivo,
            },
          },
        });
        // alert(`Formulario enviado con éxito`);
        setModalMessage(data.data.crearProtestaCiudana);
        setModalType("success");
        setModalShow(true);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {/* Datos Generales */}
        <fieldset>
          <legend>Datos Generales</legend>
          <Row className="mb-3">
            <Form.Group as={Col} md={4} controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Ingrese su nombre"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.nombre && !!formik.errors.nombre}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nombre}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="formPrimerApellido">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                name="primerApellido"
                placeholder="Ingrese su primer apellido"
                value={formik.values.primerApellido}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.primerApellido &&
                  !!formik.errors.primerApellido
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.primerApellido}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="formSegundoApellido">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                name="segundoApellido"
                placeholder="Ingrese su segundo apellido"
                value={formik.values.segundoApellido}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={4} controlId="formCorreo">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="correo"
                placeholder="Ingrese su correo electrónico"
                value={formik.values.correo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.correo && !!formik.errors.correo}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.correo}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </fieldset>

        {/* Datos del Trámite o Servicio */}
        <fieldset className="mt-4">
          <legend>
            Datos del Trámite o Servicio - Motivo de la Protesta Ciudadana
          </legend>
          <Row className="mb-3">
            <Form.Group as={Col} md={3} controlId="formDependencia">
              <Form.Label>Dependencia</Form.Label>
              <Select
                name="dependencia"
                options={dependenciasOptions}
                isLoading={dependenciasLoading}
                placeholder="Seleccione una dependencia..."
                onChange={handleDependenciaChange}
                value={dependenciasOptions.find(
                  (option) => option.value === formik.values.dependencia
                )}
                onBlur={() => formik.setFieldTouched("dependencia", true)}
              />
              {formik.touched.dependencia && !formik.values.dependencia && (
                <div className="text-danger">
                  Debe seleccionar una dependencia
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="formTramite">
              <Form.Label>Seleccione el Trámite o Servicio</Form.Label>
              <Form.Label>Trámite</Form.Label>
              <Select
                name="tramite"
                options={tramitesOptions}
                isDisabled={!formik.values.dependencia || tramitesLoading} // Deshabilitar si no hay dependencia seleccionada o trámites cargando
                isLoading={tramitesLoading}
                placeholder="Seleccione un trámite..."
                onChange={(selectedOption) =>
                  formik.setFieldValue("tramite", selectedOption?.value || "")
                }
                value={
                  tramitesOptions.find(
                    (option) => option.value === formik.values.tramite
                  ) || null // Limpia visualmente si no hay selección
                }
                onBlur={() => formik.setFieldTouched("tramite", true)}
              />
              {formik.touched.tramite && !formik.values.tramite && (
                <div className="text-danger">Debe seleccionar un trámite</div>
              )}
            </Form.Group>

            <Form.Group as={Col} md={3} controlId="formFolio">
              <Form.Label>Folio del Trámite</Form.Label>
              <Form.Control
                type="text"
                name="folio"
                placeholder="Ingrese el folio del trámite"
                value={formik.values.folio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.folio}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="formServidorPublico">
              <Form.Label>
                Nombre del Servidor Público que Atendió la Solicitud
              </Form.Label>
              <Form.Control
                type="text"
                name="servidorPublico"
                placeholder="Ingrese el nombre del servidor público"
                value={formik.values.servidorPublico}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="formLigaTramite">
              <Form.Label>Liga del trámite en línea (si aplica)</Form.Label>
              <Form.Control
                type="url"
                name="ligaTramite"
                placeholder="Ingrese la liga del trámite"
                value={formik.values.ligaTramite}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="formMotivo">
              <Form.Label>
                Motivo, acción u omisión para presentar la Protesta Ciudadana:.
              </Form.Label>
              <Select
                name="motivo"
                options={motivosOptions}
                isLoading={motivosLoading}
                placeholder="Seleccione un motivo de protesta..."
                onChange={handleMotivoChange}
                value={motivosOptions.find(
                  (option) => option.value === formik.values.motivo
                )}
                onBlur={() => formik.setFieldTouched("motivo", true)}
              />
              {formik.touched.motivo && !formik.values.motivo && (
                <div className="text-danger">Debe seleccionar un motivo</div>
              )}
            </Form.Group>
          </Row>
        </fieldset>

        {/* Datos de la Protesta */}
        <fieldset className="mt-4">
          <legend>Datos de la Protesta</legend>
          <Row className="mb-3">
            <Form.Group as={Col} md={12} controlId="formTipoProtesta">
              <Form.Label>Seleccione el Tipo de Protesta</Form.Label>
              <Select
                name="tipoProtesta"
                options={tipoProtestaOptions}
                isLoading={tipoProtestaLoading}
                placeholder="Seleccione un tipo de protesta..."
                onChange={handleTipoProtestaChange}
                value={tipoProtestaOptions.find(
                  (option) => option.value === formik.values.tipo
                )}
                onBlur={() => formik.setFieldTouched("tipoProtesta", true)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.tipoProtesta}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={12} controlId="formDescripcionHechos">
              <Form.Label>Descripción Detallada de los Hechos</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcionHechos"
                placeholder="Describa detalladamente los hechos relacionados con la protesta"
                value={formik.values.descripcionHechos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.descripcionHechos &&
                  !!formik.errors.descripcionHechos
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.descripcionHechos}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="formFechaHechos">
              <Form.Label>Fecha de los Hechos</Form.Label>
              <Form.Control
                type="datetime-local"
                name="fechaHechos"
                value={formik.values.fechaHechos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.fechaHechos && !!formik.errors.fechaHechos
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fechaHechos}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="formLugarHechos">
              <Form.Label>Lugar de los Hechos</Form.Label>
              <Form.Control
                type="text"
                name="lugarHechos"
                placeholder="Ingrese el lugar de los hechos"
                value={formik.values.lugarHechos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.lugarHechos && !!formik.errors.lugarHechos
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.lugarHechos}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </fieldset>

        {/* Subir Archivos */}
        <fieldset className="mt-4">
          <legend>Subir Archivos</legend>
          <Row className="mb-3">
            {fileInputs.map((input, index) => (
              <Col
                md={12}
                className="mb-3 d-flex align-items-center"
                key={input.id}
              >
                <Form.Group
                  controlId={`fileInput${input.id}`}
                  className="flex-grow-1"
                >
                  <Form.Label>Archivo {index + 1}</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(event) => {
                      const files = [...formik.values.archivos];
                      files[index] = event.target.files[0];
                      formik.setFieldValue("archivos", files);
                    }}
                  />
                </Form.Group>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => handleRemoveInput(input.id)}
                  disabled={fileInputs.length === 1}
                >
                  <FaTrash />
                </Button>
              </Col>
            ))}
          </Row>
          {fileInputs.length < 3 && (
            <Button variant="success" onClick={handleAddInput}>
              <FaPlus /> Agregar Archivo
            </Button>
          )}
        </fieldset>

        <Button variant="primary" type="submit" className="mt-4">
          Enviar
        </Button>
      </Form>
      <MessageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={modalMessage}
        type={modalType}
      />
    </>
  );
};

export default ProtestForm;
