import { gql } from "@apollo/client";

export const LISTAR_TRAMITES = gql`
  query OBTENER_TRAMITES($idDep: Int) {
    obtenerTramitesQuery(idDep: $idDep) {
      idDep
      idTramite
      nombre
    }
  }
`;

export const LISTAR_DEPENDENCIAS = gql`
  query OBTENER_DEPENDENCIAS {
    obtenerDependenciaQuery {
      idDep
      nombre
    }
  }
`;

export const LISTAR_TIPO_PROTESTA = gql`
  query LISTAR_TIPO_PROTESTA {
    obtenerTipoProtestaQuery {
      id
      tipo
    }
  }
`;

export const LISTAR_MOTIVOS = gql`
  query OBTENER_MOTIVOS {
    obtenerMotivosProtestaQuery {
      idMotivo
      motivo
    }
  }
`;

export const CREAR_PROTESTA = gql`
  mutation CREAR_PROTESTA($input: ProtestaInput) {
    crearProtestaCiudana(input: $input)
  }
`;
