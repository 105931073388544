import { gql } from "@apollo/client";

// Definir la consulta GraphQL para listar las licitaciones
export const LISTAR_LICITACIONES = gql`
  query ListarLicitaciones($limite: Int, $anio: Int, $pageState: String) {
    listarLicitaciones(limite: $limite, anio: $anio, pageState: $pageState) {
      licitaciones {
        id
        nombre
      resumen
      vigencia
      juntaAclaraciones
      propuestas
      falloAdjudicacion
      archivo
      publicada

      }
        nextPageState
    }
  }
`;
