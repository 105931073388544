import React from "react";
import { Modal, Button } from "react-bootstrap";

const MessageModal = ({ show, onHide, message, type }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg"  backdrop="static">
      <Modal.Header>
        <Modal.Title>{type === "success" ? "Éxito" : "Error"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p
          style={{
            color: type === "success" ? "green" : "red",
          }}
        >
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
