import react from 'react'
import CommonBanner from '../../component/Common/Banner';
import ProtestForm from '../../component/MejoraComponents/ProtestaForm';

const ProtestaCiudadana = () => {
    return (
        <>
            <CommonBanner heading="Protesta Ciudadana" page="Protesta Ciudadana" />
            <section id="contact_area_min">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            
                        </div>
                    </div>
                    <div className="contact_form_info_area">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact_form_main">
                                    <ProtestForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProtestaCiudadana;