import React, { useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "@apollo/client";
import { LISTAR_LICITACIONES } from "../../../../gql/licitaciones";
import LoadingWithText from "../../../Customs/LoadingMlc/LoadingWithText";
import LicitacionCard from "../../../Customs/LoadingMlc/LicitacionCard";

const LicitacionesDetalle = () => {
  const [pageState, setPageState] = useState(null); // Estado de la paginación
  const limite = 5; // Elementos por página
  const { loading, error, data } = useQuery(LISTAR_LICITACIONES, {
    variables: {  pageState, anio: new Date().getFullYear() },
  });

  if (error) {
    return <h1>Error</h1>;
  }

  if (loading) {
    return <LoadingWithText />;
  }

  // Datos obtenidos de la consulta
  const { listarLicitaciones } = data;
  // console.log(listarLicitaciones);
  const { licitaciones, nextPageState } = listarLicitaciones;

  return (
    <div className="col-lg-12">
      <div className="service_details_items">
        {licitaciones.length > 0 ? (
          licitaciones.map((licitacion, rowIndex) => (
            <Row key={rowIndex} className="mb-4 gx-3">
              <Col key={licitacion.id} xs={12} sm={6} md={12} className="mb-4">
                <LicitacionCard licitacion={licitacion} />
              </Col>
            </Row>
          ))
        ) : (
          <p>No hay más licitaciones disponibles.</p>
        )}
        {/* Controles de Paginación */}
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button
            disabled={!pageState} // Botón anterior deshabilitado si no hay pageState actual
            onClick={() => setPageState(null)} // Reinicia la paginación
          >
            Anterior
          </Button>
          <span>Página actual</span>
          <Button
            disabled={!nextPageState} // Botón siguiente deshabilitado si no hay nextPageState
            onClick={() => setPageState(nextPageState)} // Avanza a la siguiente página
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LicitacionesDetalle;
